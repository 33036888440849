import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PermissionRoleListResponse, CreatePermission, CreatePermissionResponse, PermissionOrganizationAvailable, CreatePermissionClientRole, ClientRoles } from '../models/PermissionModels';
import { OrgTypeEnum } from 'src/app/core/shared/enums/type-of-subject.enum';
import { OrgCreateModel, OrganizationListFilter, OrganizationListPaginationResponse, OrganizationModel } from '../models/OrganozationModel';
import { AppsettingsConfig } from '../../client-config/models/client-config';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  backendUrl: string;

  constructor(
    private http: HttpClient,
    private appsettings: AppsettingsConfig
  ) {
    this.backendUrl = appsettings.backendUrl;
  }

  getOrganizations(filter: OrganizationListFilter): Observable<OrganizationListPaginationResponse> {
    return this.http.post<OrganizationListPaginationResponse>(`${this.backendUrl}/settings/organization/list`, filter);
  }

  updateOrganization(org: OrganizationModel): Observable<boolean> {
    return this.http.patch<boolean>(`${this.backendUrl}/settings/organization`, org);
  }

  createOrganization(org: OrgCreateModel): Observable<number> {
    return this.http.post<number>(`${this.backendUrl}/settings/organization`, org);
  }

  createPermission(createData: CreatePermission): Observable<CreatePermissionResponse> {
    return this.http.post<CreatePermissionResponse>(`${this.backendUrl}/settings/permission`, createData);
  }

  getRoles4Organization(filter: PermissionOrganizationAvailable): Observable<PermissionRoleListResponse[]> {
    return this.http.post<PermissionRoleListResponse[]>(`${this.backendUrl}/settings/permission/roleList`, filter);
  }


  createPermissionClientRole(createData: CreatePermissionClientRole): Observable<ClientRoles> {
    return this.http.post<ClientRoles>(`${this.backendUrl}/settings/permission/clientRole`, createData);
  }

  getTypes() {
    return [{
      id: 0,
      enum: OrgTypeEnum.Physical,
      name: 'Физическое лицо'
    },
    {
      id: 1,
      enum: OrgTypeEnum.Legal,
      name: 'Юридическое лицо'
    },
    {
      id: 2,
      enum: OrgTypeEnum.LegalBranch,
      name: 'Филиал юридического лица'
    },
    {
      id: 3,
      enum: OrgTypeEnum.Business,
      name: 'Индивидуальный предприниматель'
    },
    {
      id: 4,
      enum: OrgTypeEnum.Agency,
      name: 'Орган исполнительной власти'
    }];
  }

  getOrgTypeName(orgType: OrgTypeEnum) {
    return this.getTypes().find(x => x.enum == orgType)?.name ?? '';
  }

  getSource() {
    return [{
      id: 0,
      name: 'ЕСИА'
    },
    {
      id: 1,
      name: 'ЕГРЮЛ/ЕГРИП'
    }];
  }

  getStatus() {
    return [{
      id: 0,
      name: 'Черновик',
      code: 'DRAFT'
    },
    {
      id: 1,
      name: 'На проверке',
      code: 'ON_VERIFICATION'
    },
    {
      id: 2,
      name: 'Подтвержено',
      code: 'VERIFIED'
    },
    {
      id: 3,
      name: 'Не подтверждено',
      code: 'NOT_VERIFIED'
    }];
  }

}
