import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsInfoComponent } from 'src/app/components/contacts-info/contacts-info.component';
import { ZitAutoOpenMenuComponent } from 'src/app/components/zit-auto-open-menu/zit-auto-open-menu.component';
import { DntCaptchaComponent } from '../auth/components/capture/dnt-captcha/dnt-captcha.component';
import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { FormsModule } from '@angular/forms';
import { NotificationComponent } from './components/notification/notification.component';
import { IconsLibraryModule } from '@platform/platform-zit-material';
import { MaterialModule } from '../lib/modules/material.module';


@NgModule({
  declarations: [
    ContactsInfoComponent,
    DntCaptchaComponent,
    NotificationComponent,
    ZitAutoOpenMenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconsLibraryModule,
    TranslocoRootModule,
    MaterialModule
  ],
  exports: [
    ContactsInfoComponent,
    DntCaptchaComponent,
    NotificationComponent,
    ZitAutoOpenMenuComponent
  ]
})
export class SharedModule { }
