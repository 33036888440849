<div class="page-email-confirm">
  <div class="text-center"><svg-icon key="statusOk" class="xxl mb-4"></svg-icon></div>
  <h1 class="text-center mb-4">{{'thankYou' | transloco }}</h1>
  <p class="text-center">
    {{'confirmSuccessInfo.emailConfirmed' | transloco }}
  </p>

  <button data-test="email-confirm-dialog_btn-go" class="primary xl mx-auto d-block mt-4 authorize-btn" mat-stroked-button disableRipple
    (click)="onGoClick()">{{'confirmSuccessInfo.signinButton' | transloco }}</button>
</div>
