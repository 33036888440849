<div class="wrapper zit-scrollbar">
  <div class="header">
    <svg-icon key="arrowLeft" (click)="onBack()" class="pointer me-3" data-test="profileStrangers_btn-back"></svg-icon>

    <h2 class="me-4">{{'profileStrangers.header' | transloco}} </h2>


    <div #confirmInfo *ngIf="showNotificationConfirm" ngClass="error" class="notification w-100 mt-3">
      <svg-icon key="errors"></svg-icon>
      <div class="body translate">
        {{'dearUser' | transloco}}
        {{this.emailStatus !== accStatusEnum.EmailConfirmed ? '\r\n'+('confirmSuccessInfo.descriptionEmail' |
        transloco):''}}
        {{!this.isPhoneConfirmed && this.needConfirmPhone ? '\r\n'+('confirmSuccessInfo.descriptionPhone' |
        transloco):''}}
      </div>
      <a (click)="showNotificationConfirm = false"><svg-icon key="greyClose"></svg-icon></a>
    </div>

    <div class="w-100 mb-3"></div>

    <div *ngIf="isSaveSuccess" [ngClass]="isSaveSuccess ? 'success' : 'error'" class="notification w-100 mt-3">
      <svg-icon key="statusOk"></svg-icon>
      <div class="body translate">{{'profileStrangers.dataSavedSuccessfully' | transloco}}</div>
      <a (click)="isSaveSuccess=false;"><svg-icon key="greyClose"></svg-icon></a>
    </div>
    <div class="w-100 mb-3"></div>

    <div class="p-0 me-3" *ngIf="accStatus as accStatus" (click)="getStatusHistory()"
      data-test="profileStrangers_btn-get-status-history">
      <div class="ring-status link">
        {{'historyStatus.title' | transloco}}
      </div>
    </div>
    <div *ngIf="emailStatus == accStatusEnum.EmailConfirmed">
      <div trigger class="d-flex me-3">
        <svg-icon key="emailConfirmed" class="me-1"></svg-icon>
        {{'profileStrangers.emailConfirmed' | transloco}}
      </div>
    </div>
    <div *ngIf="isPhoneConfirmed && needConfirmPhone">
      <div trigger class="d-flex me-3">
        <svg-icon key="phoneСonfirmed" class="me-1"></svg-icon>
        {{'profileStrangers.phoneConfirmed' | transloco}}
      </div>
    </div>

    <div class="me-auto"></div>

    <button *ngIf="emailStatus === accStatusEnum.MailNotConfirmed" (click)="sendConfirmMail()"
      class="secondary px-2 py-1 ms-3" data-test="profileStrangers_btn-send-confirm-mail">
      <svg-icon key="sendMail" class="s me-2"></svg-icon>{{'profileStrangers.activationLetter' | transloco}}
    </button>

    <button *ngIf="!isPhoneConfirmed && this.needConfirmPhone" (click)="onConfirmPhoneClick()"
      class="secondary px-2 py-1 ms-3" data-test="profileStrangers_btn-click-confirm-phone">
      <svg-icon key="sendPhone" class="s me-2"></svg-icon>{{'profileStrangers.confirmPhone' | transloco}}
    </button>

  </div>

  <form #updateForm="ngForm" class="wrapper-container">
    <div class="row pb-3 g-3">
      <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
        <mat-label>{{'profileStrangers.type' | transloco}} *</mat-label>
        <input matInput [ngModel]="getTypeStrangerName(accType)" disabled name="s_type"
          data-test="profileStrangers_input-get-type-stranger-name">
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-8 col-xl-4">
        <mat-label>{{'profileStrangers.role' | transloco}} *</mat-label>
        <input matInput [ngModel]="!!strangerInitData.roles ? strangerInitData.roles.join(', ') : ''" disabled
          name="s_role" data-test="profileStrangers_input-role">
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-12 col-md-4">
        <mat-label>{{'profileStrangers.id' | transloco}}</mat-label>
        <input matInput [ngModel]="strangerInitData.id" disabled name="s_id"
          data-test="profileStrangers_input-stranger-id">
        <svg-icon data-test="profileStrangers_input-copy-identifier" role='button' matSuffix key="copy"
          class="ms-3 me-0" (click)="copyClipboard(strangerInitData.id)"></svg-icon>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-12 col-md-4">
        <mat-label>{{'profileStrangers.dateCreated' | transloco}}</mat-label>
        <input matInput [ngModel]="strangerInitData.createdDate | date: 'dd.MM.YYYY HH:mm'" disabled
          name="s_createdDate" data-test="profileStrangers_input-stranger-created-date">
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-12 col-md-4">
        <mat-label>{{'profileStrangers.dateUpdate' | transloco}}</mat-label>
        <input matInput [ngModel]="strangerInitData.updatedDate| date: 'dd.MM.YYYY HH:mm'" disabled name="s_updatedDate"
          data-test="profileStrangers_input-stranger-updated-date">
      </mat-form-field>
    </div>

    <!--Персональные данные(личные)-->
    <div
      *ngIf="accType == strangersType.PERSON || accType == strangersType.PERSONRUS || !!customization.getOptionBool(CodeEnum.FOREIGN_ORG_PERSONAL)">
      <h3>{{'profileStrangers.titlePersonal' | transloco}}</h3>
      <div class="row pb-3 g-3">
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'profileStrangers.surname' | transloco}} *</mat-label>
          <input matInput [(ngModel)]="personalData.surname" required name="p_surname"
            data-test="profileStrangers_input-stranger-personal-data-surname" [disabled]="isDisableEdit()">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'profileStrangers.name' | transloco}} *</mat-label>
          <input matInput [(ngModel)]="personalData.firstname" required name="p_firstname"
            data-test="profileStrangers_input-stranger-personal-data-firstname" [disabled]="isDisableEdit()">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'profileStrangers.patronymic' | transloco}}</mat-label>
          <input matInput [(ngModel)]="personalData.patronymic" name="p_patronymic"
            data-test="profileStrangers_input-stranger-personal-data-patronymic" [disabled]="isDisableEdit()">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'profileStrangers.phoneNumber' | transloco}} *</mat-label>
          <input [(ngModel)]="personalData.phone" required (beforeinput)="onBeforeInputPhoneNumber($event)" matInput
            name="p_phone" data-test="profileStrangers_input-stranger-personal-data-phone"
            [disabled]="isDisableEdit()" />
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'profileStrangers.email' | transloco}} *</mat-label>
          <input [(ngModel)]="personalData.email" required matInput #p_email="ngModel" name="p_email"
            data-test="profileStrangers_input-stranger-personal-data-email" [disabled]="isDisableEdit()">
          <mat-hint class="mat-error" *ngIf="!sharedService.isEmailRegular(personalData.email)">
            {{(sharedService.checkRuEmailDomain
            ? ('registrationStrangers.errorMailRu' | transloco)
            : ('registrationStrangers.errorMail' | transloco) )}}
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="col-12 col-md-6 col-lg-4" appearance="fill"
          *ngIf="showForeignPersonalInn || accType === strangersType.PERSONRUS">
          <mat-label>{{accType === strangersType.PERSONRUS
            ?('registrationStrangers.inn' | transloco )
            :('registrationStrangers.taxNumber' | transloco)}} *
          </mat-label>
          <input [(ngModel)]="personalData.inn" mask="A*" [showMaskTyped]="false" minlength="8" maxlength="20" required
            matInput disabled name="p_inn"
            data-test="profileStrangers_input-stranger-personal-data-inn">
          <mat-error *ngIf="!sharedService.isValidForeignInn(personalData.inn)">
            {{'registrationStrangers.isValidForeignInn' | transloco}}
          </mat-error>
        </mat-form-field>
      </div>


      <h3>{{'profileStrangers.addressTitle' | transloco}}</h3>
      <div class="row pb-3 g-3">
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'registrationStrangers.country' | transloco}} *</mat-label>
          <mat-select [attr.data-test]="'registrationStrangers_select-strangers-type-person-personal-data-country-id'"
            [disabled]="isDisableEdit()" [(ngModel)]="personalData.countryId" (opened)="personalCountryId.focus()"
            required name="p_countryId">
            <mat-form-field appearance="standard" class="w-100">
              <input [attr.data-test]="'registrationStrangers_input-strangers-type-person-personal-data-country-id'"
                matInput #personalCountryId type="text" (keydown)="$event.stopPropagation()"
                placeholder="{{'registrationStrangers.search' | transloco}}">
            </mat-form-field>
            <mat-option
              [attr.data-test]="'registrationStrangers_select-strangers-type-person-personal-data-country-id-option-' + i1"
              *ngFor="let pC of getCountriesList(countries, personalCountryId.value); let i1 = index"
              [value]='pC.id'>{{getActiveLang() === 'ru' ? pC.name : pC.inEnglish}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'profileStrangers.address' | transloco}} *</mat-label>
          <input [(ngModel)]="personalData.address" required matInput name="p_address"
            data-test="profileStrangers_input-stranger-personal-data-address" [disabled]="isDisableEdit()">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4" *ngIf="accType == strangersType.PERSON">
          <mat-label>{{'profileStrangers.code' | transloco}} *</mat-label>
          <input [(ngModel)]="personalData.taxAuthority" required matInput name="p_taxAuthority"
            data-test="profileStrangers_input-stranger-personal-data-tax-authority" [disabled]="isDisableEdit()">
        </mat-form-field>
      </div>
    </div>

    <!--Выбрана иностранная организация-->
    <div *ngIf="accType == strangersType.ORGANIZATION || accType == strangersType.ORGANIZATIONRUS">
      <h3>{{'profileStrangers.titleOrganization' | transloco}}</h3>
      <div class="d-flex flex-row mb-3" *ngIf="showForeignOrgChief">
        <mat-slide-toggle class="w-auto" [checked]="organization.chief" name="o_chief" disableRipple
          (change)="organization.chief = !organization.chief" data-test="profileStrangers-toggle-is-chief">
          <div>{{'profileStrangers.headOfTheOrganization' | transloco}}</div>
        </mat-slide-toggle>
        <app-zit-auto-open-menu class="ms-2 ps-0">
          <svg-icon trigger key="question" class="s"></svg-icon>
          <div content>
            <p>{{'profileStrangers.employeeIsHeadOfOrganization' | transloco}}</p>
          </div>
        </app-zit-auto-open-menu>
      </div>
      <div class="row pb-3 g-3">
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'profileStrangers.orgForm' | transloco}} *</mat-label>
          <input [(ngModel)]="organization.olf" required matInput name="o_olf"
            data-test="profileStrangers_input-stranger-organization-olf">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'profileStrangers.orgName' | transloco}} *</mat-label>
          <input [(ngModel)]="organization.fullName" required matInput name="o_fullName"
            data-test="profileStrangers_input-stranger-organization-full-name">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-2">
          <mat-label>{{'profileStrangers.phoneNumber' | transloco}} *</mat-label>
          <input [(ngModel)]="organization.phone" required matInput name="o_phone"
            data-test="profileStrangers_input-stranger-organization-phone">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-2">
          <mat-label>{{'profileStrangers.email' | transloco}} *</mat-label>
          <input [(ngModel)]="organization.email" required matInput name="o_email"
            data-test="profileStrangers_input-stranger-organization-email">
          <mat-hint class="mat-error" *ngIf="!sharedService.isEmailRegular(organization.email)">
            {{(sharedService.checkRuEmailDomain
            ? ('registrationStrangers.errorMailRu' | transloco)
            : ('registrationStrangers.errorMail' | transloco) )}}
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="col-12 col-md-6 col-lg-4" appearance="fill"
          *ngIf="showForeignOrgInn || accType == strangersType.ORGANIZATIONRUS">
          <mat-label>{{accType == strangersType.ORGANIZATIONRUS
            ?('registrationStrangers.inn' | transloco )
            :('registrationStrangers.taxNumber' | transloco)}} *
          </mat-label>
          <input [(ngModel)]="organization.inn" mask="A*" [showMaskTyped]="false" minlength="8" maxlength="20" required disabled
            matInput name="o_inn" data-test="profileStrangers_input-stranger-organization-inn">
          <mat-error *ngIf="!sharedService.isValidForeignInn(organization.inn)">
            {{'registrationStrangers.isValidForeignInn' | transloco}}
          </mat-error>
        </mat-form-field>
      </div>


      <h3>{{'profileStrangers.addressTitle2' | transloco}}</h3>
      <div class="row pb-3 g-3">
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'registrationStrangers.country' | transloco}} *</mat-label>
          <mat-select [(ngModel)]="organization.countryId" (opened)="organizationCountryId.focus()" required
            name="o_countryId" data-test="registrationStrangers_select-organization-country-id">
            <mat-form-field appearance="standard" class="w-100">
              <input matInput #organizationCountryId type="text" (keydown)="$event.stopPropagation()"
                placeholder="{{'registrationStrangers.search' | transloco}}"
                data-test="registrationStrangers_input-organization-country-id">
            </mat-form-field>
            <mat-option [attr.data-test]="'registrationStrangers_select-organization-country-id-option-' + i2"
              *ngFor="let oC of getCountriesList(countries, organizationCountryId.value); let i2 = index"
              [value]='oC.id'>{{getActiveLang() == 'ru' ? oC.name : oC.inEnglish}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'profileStrangers.address' | transloco}} *</mat-label>
          <input matInput [(ngModel)]="organization.address" required name="o_address"
            data-test="profileStrangers_input-organization-address">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4"
          *ngIf="accType == strangersType.ORGANIZATION">
          <mat-label>{{'profileStrangers.code' | transloco}} *</mat-label>
          <input matInput [(ngModel)]="organization.taxAuthority" required name="o_taxAuthority"
            data-test="profileStrangers_input-organization-tax-authority">
        </mat-form-field>
      </div>
    </div>


    <!--Блок с данными о ТС и владельце ТС-->
    <ng-container
      *ngIf="showStrangersVehicles && (accType == strangersType.PERSON || accType == strangersType.ORGANIZATION)">
      <h3>{{'profileStrangers.tsTitle' | transloco}}</h3>
      <div class="row pb-3 g-3">
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'profileStrangers.mark' | transloco}} *</mat-label>
          <input disabled matInput [(ngModel)]="vehicleData.brand" required name="v_brand"
            data-test="profileStrangers_input-stranger-vehicle-data-brand">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'profileStrangers.model' | transloco}} *</mat-label>
          <input disabled matInput [(ngModel)]="vehicleData.model" required name="v_model"
            data-test="profileStrangers_input-stranger-vehicle-data-model">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'profileStrangers.numberModel' | transloco}} *</mat-label>
          <input disabled matInput [(ngModel)]="vehicleData.srn" required name="v_srn"
            data-test="profileStrangers_input-stranger-vehicle-data-srn">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'profileStrangers.numberIdentity' | transloco}} *</mat-label>
          <input disabled matInput [(ngModel)]="vehicleData.vin" required name="v_vin"
            data-test="profileStrangers_input-stranger-vehicle-data-vin">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
          <mat-label>{{'registrationStrangers.country' | transloco}} *</mat-label>
          <mat-select disabled [(ngModel)]="vehicleData.countryId" (opened)="vehicleCountryId.focus()" id="v_countryId"
            name="v_countryId" required data-test="registrationStrangers_select-vehicle-country-id">
            <mat-form-field appearance="standard" class="w-100">
              <input matInput #vehicleCountryId type="text" (keydown)="$event.stopPropagation()"
                placeholder="{{'registrationStrangers.search' | transloco}}"
                data-test="registrationStrangers_input-vehicle-country-id">
            </mat-form-field>
            <mat-option [attr.data-test]="'registrationStrangers_select-vehicle-data-country-id-option-' + i3"
              *ngFor="let vC of getCountriesList(countries, vehicleCountryId.value); let i3 = index"
              [value]='vC.id'>{{getActiveLang() == 'ru' ? vC.name : vC.inEnglish}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>


      <!-- Информация о владельце транспортного средства -->
      <h3>{{'profileStrangers.ownerTitle' | transloco}}</h3>
      <div class="pb-3 g-3">
        <mat-slide-toggle disabled [checked]="isMatch" name="v_owner" (change)="isMatch = !isMatch" disableRipple
          disableRipple data-test="profileStrangers_toggle-owner-title">
          {{'profileStrangers.ownerTitleToggle' | transloco}}
        </mat-slide-toggle>
      </div>


      <!--Блок с данными о владельце тс (если не совпадают с заявителем)-->
      <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4" *ngIf="!isMatch">
        <mat-label>{{'profileStrangers.type' | transloco}} *</mat-label>
        <mat-select disabled [(ngModel)]="typeUserTS" name="typeUserTS" required
          data-test="profileStrangers_select-type-user-ts">
          <mat-option *ngFor="let type of strangersOwnerTypes; let i5 = index" [value]="type"
            [attr.data-test]="'profileStrangers_select-type-user-ts-option-' + i5">
            <span>{{ getActiveLang() == 'ru' ? type.name : type.inEnglish }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Информация о владельце транспортного средства Иностранный гражданин -->
      <div *ngIf="showStrangersVehicles && (typeUserTS?.id == strangersOwnerType.PERSON && !isMatch)">
        <h3>{{'profileStrangers.titlePersonal' | transloco}}</h3>
        <div class="row pb-3 g-3">
          <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
            <mat-label>{{'profileStrangers.surname' | transloco}} *</mat-label>
            <input disabled matInput [(ngModel)]="owner.surname" required name="vo_surname"
              data-test="profileStrangers_input-stranger-vehicle-data-owner-surname">
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
            <mat-label>{{'profileStrangers.name' | transloco}} *</mat-label>
            <input disabled matInput [(ngModel)]="owner.firstname" required name="vo_firstName"
              data-test="profileStrangers_input-stranger-vehicle-data-owner-firstname">
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
            <mat-label>{{'profileStrangers.patronymic' | transloco}}</mat-label>
            <input disabled matInput [(ngModel)]="owner.patronymic" name="vo_patronymic"
              data-test="profileStrangers_input-stranger-vehicle-data-owner-patronymic">
          </mat-form-field>
        </div>


        <h3>{{'profileStrangers.addressTitle' | transloco}}</h3>
        <div class="row pb-3 g-3">
          <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
            <mat-label>{{'profileStrangers.country' | transloco}} *</mat-label>
            <mat-select disabled [(ngModel)]="owner.countryId" (opened)="ownerCountryId.focus()" required
              name="w_countryId" data-test="registrationStrangers_select-person-owner-country-id">
              <mat-form-field appearance="standard" class="w-100">
                <input matInput #ownerCountryId type="text" (keydown)="$event.stopPropagation()"
                  placeholder="{{'registrationStrangers.search' | transloco}}"
                  data-test="registrationStrangers_input-person-owner-country-id">
              </mat-form-field>
              <mat-option *ngFor="let i of getCountriesList(countries, ownerCountryId.value); let k = index"
                [value]='i.id' [attr.data-test]="'registrationStrangers_input-person-owner-country-id-option-' + k">
                {{getActiveLang() == 'ru' ? i.name : i.inEnglish}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
            <mat-label>{{'profileStrangers.address' | transloco}} *</mat-label>
            <input disabled matInput [ngModel]="owner.address" required name="vo_address"
              data-test="profileStrangers_input-stranger-vehicle-data-owner-address">
          </mat-form-field>
        </div>
      </div>

      <!--Выбрана иностранная организация-->
      <div *ngIf="showStrangersVehicles && (typeUserTS?.id == strangersOwnerType.LEGAL && !isMatch)">
        <h3>{{'profileStrangers.titleOrganization' | transloco}}</h3>
        <div class="row pb-3 g-3">
          <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
            <mat-label>{{'profileStrangers.orgForm' | transloco}} *</mat-label>
            <input disabled matInput [(ngModel)]="owner.olf" required name="vo_olf"
              data-test="profileStrangers_input-stranger-vehicle-data-owner-olf">
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
            <mat-label>{{'profileStrangers.orgName' | transloco}} *</mat-label>
            <input disabled matInput [(ngModel)]="owner.fullName" required name="vo_fullName"
              data-test="profileStrangers_input-stranger-vehicle-data-owner-full-name">
          </mat-form-field>
        </div>
        <h3>{{'profileStrangers.addressTitle2' | transloco}}</h3>
        <div class="row pb-3 g-3">
          <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
            <mat-label>{{'registrationStrangers.country' | transloco}} *</mat-label>
            <mat-select disabled [(ngModel)]="owner.countryId" (opened)="ownerCountryId.focus()" name="w_countryId"
              required data-test="registrationStrangers_select-organization-owner-country-id">
              <mat-form-field appearance="standard" class="w-100">
                <input matInput #ownerCountryId type="text" (keydown)="$event.stopPropagation()"
                  placeholder="{{'registrationStrangers.search' | transloco}}"
                  data-test="registrationStrangers_input-organization-owner-country-id">
              </mat-form-field>
              <mat-option [attr.data-test]="'registrationStrangers_select-organization-owner-country-id-option-' + k"
                *ngFor="let i of getCountriesList(countries, ownerCountryId.value); let k = index"
                [value]='i.id'>{{getActiveLang() == 'ru' ? i.name : i.inEnglish}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-12 col-md-6 col-lg-4">
            <mat-label>{{'profileStrangers.address' | transloco}} *</mat-label>
            <input disabled matInput [(ngModel)]="owner.address" required name="vo_address"
              data-test="profileStrangers_input-stranger-vehicle-data-owner-address">
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <div *ngIf="serverValidationErrors.length" class="notification error mb-3">
      <div class="body" *ngFor="let err of serverValidationErrors">{{err}}</div>
    </div>

    <div class="d-flex mt-2" *ngIf="checkStatusForSave()">
      <button class="secondary me-3" (click)="updateData()" data-test="profileStrangers_btn-registration">
        {{'cancel' | transloco}}
      </button>
      <button class="primary" (click)="updateForm.form.markAllAsTouched();saveClick()"
        data-test="profileStrangers_btn-registration">
        {{'save' | transloco}}
      </button>
    </div>
  </form>

</div>
