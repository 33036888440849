import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './components/app/app.component';
import { PaginatorModule } from './components/paginator/app.paginator.module';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LayoutModule } from './modules/lib/modules/layout/layout.module';
import { MaterialModule } from './modules/lib/modules/material.module';
import { ClientConfigModule } from './modules/client-config/client-config.module';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomMatPaginatorIntl } from './custom-mat-paginator-int';
import { registerLocaleData } from '@angular/common';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { CheckStatusComponent } from './components/check-status/check-status.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ServerErrorPageComponent } from './components/server-error-page/server-error-page.component';
import { AddDocDialogComponent } from './components/add-doc-dialog/add-doc-dialog.component';
import { RemoveDocDialogComponent } from './components/remove-doc-dialog/remove-doc-dialog.component';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { NgxMaskModule } from 'ngx-mask';
import localeRu from '@angular/common/locales/ru';
import { TranslocoRootModule } from './transloco-root.module';
import { IconsLibraryModule } from '@platform/platform-zit-material';
import { StatusHistoryComponent } from './components/statusHistory/statusHistory.component';
import { OkCancelDialogComponent } from './components/ok-cancel-dialog/ok-cancel-dialog.component';
import { FormsModule } from '@angular/forms';
import { CookieModule } from 'ngx-cookie';
import { ProfileStrangersComponent } from "./components/profileStrangers/profileStrangers.component";
import { ProfileMainComponent } from "./components/profileMain/profileMain.component";
import { EsiaErrorPageComponent } from './components/esia-error-page/esia-error-page.component';
import { Organization4cardComponent } from './components/card/Organization/organization4card.component';
import { AddOrganization2UserComponent } from './modules/organizations/components/addOrganization2User/addOrganization2User.component';
import { DeleteRecordModalComponent } from './components/deleteRecordModal/deleteRecordModal.component';
import { RefusalOfRegistrationComponent } from './components/card/refusalOfRegistration/RefusalOfRegistration.component';
import { CardComponent } from './components/card/card.component';
import { FileSizePipe } from './core/shared/filesize.pipe';
import { SharedModule } from './modules/shared/shared.module';
import { HttpCredentialsInterceptor } from './core/http/httpCredintials';
import { AppsettingsConfig } from './modules/client-config/models/client-config';
import { ConfigService } from './modules/client-config/services/config.service';
import { AuthInterceptor } from './core/http/auth.interceptor';
import { ActivatePhoneDialogComponent } from './modules/auth/components/modals/activate-phone-dialog/activate-phone-dialog.component';
import { CountdownDirective } from './core/directives/countdown.directive';
import { PhonePipe } from './core/pipes/phone.pipe';
import { EmailConfirmSuccessComponent } from './modules/auth/components/modals/email-confirm-success/email-confirm-success.component';
registerLocaleData(localeRu, 'ru');

@NgModule({
  declarations: [
    AppComponent,
    CheckStatusComponent,
    PageNotFoundComponent,
    AddDocDialogComponent,
    RemoveDocDialogComponent,
    ActivatePhoneDialogComponent,
    EmailConfirmSuccessComponent,
    ServerErrorPageComponent,
    EsiaErrorPageComponent,
    StatusHistoryComponent,
    Organization4cardComponent,
    AddOrganization2UserComponent,
    OkCancelDialogComponent,
    ProfileStrangersComponent,
    ProfileMainComponent,
    DeleteRecordModalComponent,
    RefusalOfRegistrationComponent,
    CardComponent,
    CountdownDirective,
    PhonePipe,
    FileSizePipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    MatMenuModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    PaginatorModule,
    LayoutModule,
    ClientConfigModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', { registrationStrategy: 'registerWhenStable:20000' }),
    NgxMaskModule.forRoot({
      showMaskTyped: true,
    }),
    TranslocoRootModule,
    IconsLibraryModule,
    CookieModule.forRoot()
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCredentialsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => configService.loadClientConfig(),
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: SwRegistrationOptions,
      useFactory: (config: AppsettingsConfig) => ({
        enabled: config.enableServiceWorker
      }),
      deps: [AppsettingsConfig],
    },
    { provide: Window, useValue: window },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
