import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, tap } from "rxjs/operators";
import { NavigationExtras, Router } from '@angular/router';
import { Location } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private location: Location,
    private router: Router,
    private matDialog: MatDialog) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        console.error(err);
        if (err.status === 404 || err.status === 500 || err.status === 502 || err.status === 503 || err.status === 504) {
          const params: NavigationExtras = {
            queryParams: {
              code: err.status,
              message: err.message
            },
          };
          if (err.status === 404 && err.message.includes('/server-error?code=500')) {
            const url = err.url;
            if (url) {
              const search = new URL(url).search.substring(1)
              const _params = new URLSearchParams(search);
              params.queryParams!.code = _params.get('code');
              params.queryParams!.message = _params.get('message');
            }
          }
          this.router.navigate(['/server-error'], params);
          this.matDialog.closeAll();
        }

        if (err.status === 401 && this.location.path().indexOf('/auth/signin') === -1 && this.location.path().indexOf('/specialists-signin') === -1) {
          this.router.navigate(['/auth/signin']);
          this.matDialog.closeAll();
          return EMPTY;
        }

        return throwError(err);
      })
    );
  }
}
