<div mat-dialog-title>
  <h1 *ngIf="!isConfirmSuccess">{{isCaptureValid ? ('activatePhone.titleConfirm' | transloco) :
    ('activatePhone.titleCaptcha' | transloco)}}</h1>
  <button (click)="onCloseClick()" class="close-btn ms-auto"></button>
</div>

<!-- Проверка капчи -->
<div mat-dialog-content [hidden]="!(!isCaptureValid && !isLoading)">
  <app-notification #notification [hidden]="!notification?.isShow" class="w-100"></app-notification><br>
  <dnt-captcha #appDntCaptcha [apiUrl]="captchaApiUrl" data-test="activate-phone-captcha"></dnt-captcha>
  <div class="text-center">
    <button (click)="onRetryClick()" class="primary xl mt-3 confirm-button" mat-raised-button disableRipple
      data-test="activate-phone-dialog_btn-continue">{{'activatePhone.continueConfirm' | transloco}}</button>
  </div>
</div>


<!-- Истекло время, получить новый телефон -->
<div mat-dialog-content *ngIf="callTimerVal === 0 && !isLoading">
  <div class="text-center">
    <div class="text-xl mb-3">{{'activatePhone.expirePhoneCall' | transloco}}</div>
    <button (click)="onResetTimerClick()" class="primary xl mt-3 confirm-button" mat-raised-button disableRipple
      data-test="activate-phone-dialog_btn-retry">{{'activatePhone.reCall' | transloco}}</button>
  </div>
</div>


<!-- Отображение телефона для звонка -->
<div mat-dialog-content *ngIf="!isConfirmSuccess && isCaptureValid && callTimerVal > 0 && !isLoading" class="p-3">
  <p class="text-xl">
    {{'activatePhone.infoMessagePart1' | transloco}}
    {{data.phone}}
    {{'activatePhone.infoMessagePart2' | transloco}}</p>
  <div class="phone"><span class="text-big">{{flashCallPhone.phone | phone:'RU'}}</span></div>
  <mat-progress-bar class="mb-3 m-auto" [value]="callTimerVal*100/callExpireSeconds"></mat-progress-bar>
  <div class="text-xl text-second text-center">
    {{'activatePhone.infoMessageRecall' | transloco}}
    <span [countdown]="callExpireSeconds" (value)="callTimerVal = $event">{{ callTimerVal }}</span> {{'activatePhone.infoMessageSeconds' | transloco}}.
  </div>
</div>


<!-- Лоудер -->
<div mat-dialog-content *ngIf="isLoading" class="p-3">
  <div *ngIf="isLoading" class="col-12">
    <mat-spinner [diameter]="100" class="mt-5 m-auto"></mat-spinner>
  </div>
</div>


<!-- Инфо что успешно подтвердили -->
<div mat-dialog-content *ngIf="isConfirmSuccess" class="p-3 d-flex flex-column text-center">
  <div><svg-icon key="statusOk" class="xxl m-4"></svg-icon></div>
  <div class="text-big mb-4">{{'thankYou' | transloco}}</div>
  <p class="text-xl mb-3"> {{'activatePhone.confirmSuccess' | transloco}}</p>
</div>
