import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription, timer } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { ConfigService } from 'src/app/modules/client-config/services/config.service';
import { CodeEnum } from 'src/app/core/shared/enums/codeEnum';
import { DntCaptchaComponent } from '../../capture/dnt-captcha/dnt-captcha.component';
import { TranslocoService } from '@ngneat/transloco';
import { HttpClient } from '@angular/common/http';
import { NotificationComponent } from 'src/app/modules/shared/components/notification/notification.component';
import { AppsettingsConfig } from 'src/app/modules/client-config/models/client-config';
import { ErrorCodesEnum } from 'src/app/core/shared/enums/errorCodesEnum';
import { FlashcallTypeEnum } from 'src/app/core/shared/enums/flashcallTypeEnum';

export interface IActivatePhoneDialogModel {
  flashcallType: FlashcallTypeEnum,
  isStranger: true
  phone: string,
  email?: string// передается только для FlashcallTypeEnum.RECOVERY_PASSWORD
}
export interface IFlashсallGetNumberResponse {
  callId: string,
  phone: string
}

/**
 * @title Окно активации телефона через сервис FlashCall от Плюсофона
 * @callExpireSeconds время жизни сессии, в течении которого можно позвонить пока телефон не устарел(по умолчанию 120 секунд)
 * @callTimerVal оставшееся время сессии
 */
@Component({
  selector: 'app-activate-phone-dialog',
  templateUrl: './activate-phone-dialog.component.html',
  styleUrls: ['./activate-phone-dialog.component.scss']
})
export class ActivatePhoneDialogComponent implements OnDestroy {
  @ViewChild('notification') notification!: NotificationComponent;
  @ViewChild('appDntCaptcha') appDntCaptcha: DntCaptchaComponent;
  private subs: Subscription = new Subscription();
  callExpireSeconds: number;
  callTimerVal: number;
  monitorPhoneConfirmedInterval = 5;
  isConfirmSuccess = false;
  isLoading = false;
  flashCallPhone = {} as IFlashсallGetNumberResponse;
  isCaptureValid = false;
  captchaApiUrl = '/account/createcaptcha';

  constructor(
    private dialogRef: MatDialogRef<ActivatePhoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IActivatePhoneDialogModel,
    private authService: AuthService,
    private translocoService: TranslocoService,
    httpClient: HttpClient,
    appsettings: AppsettingsConfig,
    configService: ConfigService
  ) {
    console.info(data);
    this.captchaApiUrl = appsettings.backendUrl + this.captchaApiUrl;
    this.callExpireSeconds = this.callTimerVal = configService.customization.getOptionNumber(CodeEnum.FLASHCALL_EXPIRE_SECONDS);
    this.appDntCaptcha = new DntCaptchaComponent(
      httpClient,
      translocoService
    );
  }



  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onCloseClick() {
    this.dialogRef.close(this.isConfirmSuccess);
  }

  onResetTimerClick() {
    this.isCaptureValid = false;
    this.callTimerVal = this.callExpireSeconds;
    this.notification.hide();
    this.appDntCaptcha.doRefresh()
  }

  onRetryClick() {
    this.getFlashCallPhone();
  }

  /**
   * Запрос номера FlashCall для обратного звонка
   */
  getFlashCallPhone(): void {
    this.isLoading = true;

    this.subs.add(
      this.authService.getFlashCall(this.getCaptchaFormData(), this.data.flashcallType, this.data.email).subscribe(res => {
        this.flashCallPhone = res;
        this.isCaptureValid = true;
        this.isLoading = false;
        this.monitorPhoneConfirmation();
      }, err => {
        this.isLoading = false;
        console.info(err);
        if (err.error.title as ErrorCodesEnum) {
          if (err.error.title == ErrorCodesEnum.FLASHCALL_EXTERNAL_BADREQUEST) {
            this.notification.showError(err.error.detail);
          }
          else {
            if (err.error.title == ErrorCodesEnum.CAPTCHA_NOT_VALID) {
              this.appDntCaptcha.correctSum = false;
            }
            this.notification.showError(this.translocoService.translate('errorCodes.' + err.error.title));
          }
        }
        else {
          this.dialogRef.close(false);
        }
      }));
  }
  /**
   * По таймеру проверять на бэке, прошло ли подтверждение телефона
   */
  monitorPhoneConfirmation(): void {
    const monitoringTimer = timer(this.monitorPhoneConfirmedInterval * 1000, this.monitorPhoneConfirmedInterval * 1000);
    const sub = monitoringTimer.subscribe(_ => {
      if (this.callTimerVal > 0 && !this.isConfirmSuccess) {
        if (this.data.flashcallType == FlashcallTypeEnum.CONFIRM_PHONE) {
          this.isPhoneConfirmed();
        }
        else if (this.data.flashcallType == FlashcallTypeEnum.RECOVERY_PASSWORD) {
          this.isRecoveryPasswordSuccess();
        }
      }
    });
    this.subs.add(sub);
  }
  /**
   * Проверка, что телефон подтвержден(у пользователя статус УЗ поменялся на 14)
   */
  isPhoneConfirmed(): void {
    const sub = this.authService.isPhoneConfirmed().subscribe(isConfirmed => {
      if (isConfirmed) {
        this.isConfirmSuccess = true;
      }
    });
    this.subs.add(sub);
  }
  /**
   * Мониторим раз в N секунд, подтвердили ли телефон для продолжения восстановления пароля
   */
  isRecoveryPasswordSuccess(): void {
    const sub = this.authService.isRecoveryPasswordSuccess(this.flashCallPhone.callId, this.data.email).subscribe(isConfirmed => {
      if (isConfirmed) {
        this.isConfirmSuccess = true;
        //закрыть окно и послать true
        this.dialogRef.close(true);
      }
    });
    this.subs.add(sub);
  }
  /**
   * Считать параметры капчи
   */
  getCaptchaFormData(): FormData {
    const formData = new FormData();
    formData.append('DNTCaptchaText', this.appDntCaptcha.text);
    formData.append('DNTCaptchaToken', this.appDntCaptcha.token);
    formData.append('DNTCaptchaInputText', this.appDntCaptcha.inputText);
    return formData;
  }
}
