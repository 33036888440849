<div class="row g-3 pb-3 dnt-captcha">
  <div class="col-6 col-md-9">
    <mat-form-field appearance="fill">
      <mat-label>{{'capcha.header' | transloco}}</mat-label>
      <input data-test="dnt-captcha_input-input-text" matInput [id]="inputName" [placeholder]="getPaceholder()" autocomplete="off"
             [(ngModel)]="inputText" (input)="onInputTextChange()" required>
    </mat-form-field>
    <mat-error *ngIf="!correctSum" class="small">* {{'capcha.error' | transloco}}</mat-error>
  </div>
  <div class="col-6 col-md-3">
    <div class="dnt-captcha" [id]="apiResponse.dntCaptchaId">
      <img alt="captcha" id="dnt-captcha-img" [src]="apiResponse.dntCaptchaImgUrl">
      <!-- Поменять картинку  <a class="glyphicon glyphicon-refresh btn-sm" style="cursor: pointer" id="dntCaptchaRefreshButton" name="dntCaptchaRefreshButton" (click)="doRefresh()"></a>-->
      <input data-test="dnt-captcha_input-api-response-dnt-captcha-text-value" [id]="hiddenInputName" [name]="hiddenInputName" type="hidden" [value]="apiResponse.dntCaptchaTextValue">
      <input data-test="dnt-captcha_input-api-response-dnt-captcha-token-value" [id]="hiddenTokenName" [name]="hiddenTokenName" type="hidden" [value]="apiResponse.dntCaptchaTokenValue">
    </div>
  </div>
</div>
