import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, Subscription } from "rxjs";
import { EsiaUrl } from "../models/Esia/EsiaUrl";
import ISystem from "../models/interfaces/ISystem";
import IEsiaResponse from "../models/interfaces/IEsiaResponse";
import LogoutUrl from "../models/interfaces/ILogoutUrl";
import IAuthorizationLoginResponse from '../models/response/IAuthorizationLoginResponse';
import { CookiesService, FIO_COOKIE } from '../../../core/services/cookies.service';
import AccountUserInfo from 'src/app/core/models/AccountUserInfo';
import { AppsettingsConfig } from '../../client-config/models/client-config';
import { IFlashсallGetNumberResponse } from '../components/modals/activate-phone-dialog/activate-phone-dialog.component';
import { FlashcallTypeEnum } from 'src/app/core/shared/enums/flashcallTypeEnum';



@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  public popupCloseTimeout = 2000;
  public userName: string | null | undefined = "";
  private subs: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private cookieService: CookiesService,
    public userInfo: AccountUserInfo,
    private appsettings: AppsettingsConfig
  ) {
    // TODO прокидывать язык c бэка и записывать в куки
    this.userName = !!userInfo?.surname
      ? userInfo?.surname + " "
      + (!userInfo?.name ? '' : userInfo?.name[0] + ". ")
      + (!userInfo?.patronymic ? '' : userInfo?.patronymic[0] + ".")
      : userInfo?.organizationName;
    this.cookieService.saveCookie(FIO_COOKIE, this.userName ?? '');
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  authorizeLocal(
    username: string,
    password: string,
    isStranger: boolean,
    client: string
  ): Observable<IAuthorizationLoginResponse> {
    return this.http.post<IAuthorizationLoginResponse>(
      `${this.appsettings.backendUrl}/account/login`,
      { login: username, password, isStranger, clientId: client }
    );
  }


  // Сгенерировать url для редиректа на получение авторизационного кода на Госуслугах
  getEsiaUrl(oauthKey?: string, client?: string): Observable<EsiaUrl> {
    return this.http.get<EsiaUrl>(
      `${this.appsettings.backendUrl}/esia/esia-url?oAuthKey=${oauthKey}&client=${client}`
    );
  }

  logout(redirectUri = ''): any {
    this.cookieService.removeCookie(FIO_COOKIE);

    this.subs.add(this.logoutCookie().subscribe(_ => {

      if (this.userInfo?.accountType == "esia") {
        this.subs.add(this.getLogoutEsiaUrl(redirectUri).subscribe((url) => {
          window.location.href = url;
        }));
      }
      else if (!!redirectUri) {
        window.location.href = redirectUri;
      }
      else {
        window.location.href = '/auth/signin';
      }
    }));
  }

  // пользователь залогинился (но возможно у него нет прав)
  isAuthenticated(): boolean {
    return !!this.userInfo;
  }

  /**
   * Если пользователь авторизован -получаем только доступные ИС, если НЕ авторизован - все ИС
   * @returns Список информационных систем
   */
  getSystemsList(): Observable<ISystem[]> {
    return this.http.get<ISystem[]>(
      `${this.appsettings.backendUrl}/account/GetUserSystems`
    );
  }

  getImage(clientId: number) {
    return this.http.get(
      `${this.appsettings.backendUrl}/account/GetImage?clientId=${clientId}`,
      {
        observe: 'response',
        responseType: 'blob',
      }
    );
  }
  // Получаем url для выхода из ЕСИА
  logoutCookie() {
    return this.http.get(`${this.appsettings.backendUrl}/oauth/logout`);
  }
  // Получаем url для выхода из ЕСИА
  getLogoutEsiaUrl(redirectUri: string): Observable<string> {
    return this.http.get(
      `${this.appsettings.backendUrl}/oauth/CreateLogoutUrl?redirectUri=${redirectUri}`,
      { responseType: 'text' }
    );
  }

  // Получаем по авторизационному коду госуслуг токен и скоуп по пользователю
  getUserTokenAndScope(code: string, state: string, client: string): Observable<IEsiaResponse> {
    return this.http.get<IEsiaResponse>(
      `${this.appsettings.backendUrl}/esia/GetUserTokenAndScope?code=${code}&state=${state}&client=${client}`
    );
  }

  /**
   * получаем список урлов для выхода из каждой из ИС в фоновом режиме
   */
  getLogoutUrls(): Observable<LogoutUrl[]> {
    return this.http.get<LogoutUrl[]>(
      `${this.appsettings.backendUrl}/oauth/GetLogoutUrls`
    );
  }
  /**
   * Если в урле пришла ссылка на логаут, редиректим на логаут
   * @param username почта - логин пользователя
   * @param password пароль пользователя
   * @param errCallback если не смогли войти, выполнить эту функцию
   */
  authorizeOauth(
    request: FormData,
  ): Observable<IAuthorizationLoginResponse> {
    return this.http.post<IAuthorizationLoginResponse>(
      `${this.appsettings.backendUrl}/oauth/authorize`,
      request);
  }
  /**
     * получаем телефон коллцентра FlashCall, для подтверждения телефона пользователя
     */
  getFlashCall(request: FormData, type: FlashcallTypeEnum, email?: string): Observable<IFlashсallGetNumberResponse> {
    return this.http.post<IFlashсallGetNumberResponse>(
      `${this.appsettings.backendUrl}/settings/phone/flashcall?type=${type}&email=${email}`, request
    );
  }
  /**
    * Мониторим раз в N секунд, прошло ли подтверждение телефона
    */
  isPhoneConfirmed(): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.appsettings.backendUrl}/settings/phone/monitoring`, {}
    );
  }
  /**
  * Мониторим раз в N секунд, подтвердили ли телефон для продолжения восстановления пароля
  */
  isRecoveryPasswordSuccess(callId: string, email?: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.appsettings.backendUrl}/settings/phone/recovery-password-success`, { callId: callId, email: email }
    );
  }
  GetUserAccountType() {
    if (!this.isAuthenticated()) return "";
    return this.userInfo?.accountType;
  }
  getUserInfo(): Observable<AccountUserInfo> {
    return this.http.post<AccountUserInfo>(
      `${this.appsettings.backendUrl}/account/userinfo`, {}
    );
  }
}
