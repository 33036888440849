import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DNTCaptchaApiResponse } from "./dnt-captcha-api-response";
import { DNTCaptchaLanguage } from "./dnt-captcha-language";
import {Observable} from "rxjs";
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: "dnt-captcha",
  templateUrl: "./dnt-captcha.component.html",
  styleUrls: ["./dnt-captcha.component.scss"],
})
export class DntCaptchaComponent implements OnInit {
  apiResponse = new DNTCaptchaApiResponse();
  hiddenInputName = "DNTCaptchaText";
  hiddenTokenName = "DNTCaptchaToken";
  inputName = "DNTCaptchaInputText";
  focusState = false;
  correctSum = false;

  @Input() public text: string='';
  @Output() textChange = new EventEmitter<string>();

  @Input() public token: string='';
  @Output() tokenChange = new EventEmitter<string>();
  @Input() public inputText: string='';
  @Output() inputTextChange = new EventEmitter<string>();

  @Input() placeholder: string = 'Введите сумму чисел';
  @Input() apiUrl: string = '';
  @Input() backColor: string= '#f7f3f3';
  @Input() fontName: string= 'Tahoma';
  @Input() fontSize: number=18;
  @Input() foreColor: string= '#111111';
  @Input() language: DNTCaptchaLanguage =DNTCaptchaLanguage.English;
  @Input() max: number=0;
  @Input() min: number=0;

  constructor(private http: HttpClient, private translocoService: TranslocoService) {}

  ngOnInit() {
    this.doRefresh();
  }

  getCaptchaInfo(): Observable<DNTCaptchaApiResponse> {
    return this.http.get<DNTCaptchaApiResponse>(`${this.apiUrl}`, {
        withCredentials: true /* For CORS */,
      });
  }

  doRefresh() {
    this.inputText = "";
    this.getCaptchaInfo().subscribe((data) => {
      this.apiResponse = data;
      this.text = data.dntCaptchaTextValue;
      this.onTextChange();
      this.token = data.dntCaptchaTokenValue;
      this.onTokenChange();
    });
  }

  onTextChange() {
    this.textChange.emit(this.text);
  }

  onTokenChange() {
    this.tokenChange.emit(this.token);
  }

  onInputTextChange() {
    this.correctSum = true;
    this.inputTextChange.emit(this.inputText);
  }

  getPaceholder(): string{
    if(this.isRU())
      return "Введите сумму чисел";
    else return "Enter the sum of the numbers"
  }
  isRU() {
    return this.translocoService.getActiveLang() == "ru";
  }
}
