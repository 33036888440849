import { Component, Input } from '@angular/core';

export enum NotificationEnum {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning'
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  @Input() message = '';
  @Input() isShow = false;
  @Input() status: string = NotificationEnum.ERROR;

  constructor() { }

  public show() {
    this.isShow = true;
  }

  public hide() {
    this.isShow = false;
  }

  public showInfo(message: string) {
    this.isShow = true;
    this.status = NotificationEnum.INFO;
    this.message = message;
  }

  public showSuccess(message: string) {
    this.isShow = true;
    this.status = NotificationEnum.SUCCESS;
    this.message = message;
  }

  public showWarning(message: string) {
    this.isShow = true;
    this.status = NotificationEnum.WARNING;
    this.message = message;
  }

  public showError(message: string) {
    this.isShow = true;
    this.status = NotificationEnum.ERROR;
    this.message = message;
  }

  getIcon() {
    switch (this.status) {
      case NotificationEnum.INFO: return "information";
      case NotificationEnum.SUCCESS: return "statusOk";
      case NotificationEnum.WARNING: return "warning";
      case NotificationEnum.ERROR: return "errors";
      default: return "errors";
    }
  }

}
