import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { filter, pluck } from 'rxjs/operators';


@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(
    private translocoService: TranslocoService
  ) {
    super();

    this.translocoService.events$.pipe(
      filter(e => e.type === 'langChanged'),
      pluck('payload')
    ).subscribe(({ langName, scope }) => this.getAndInitTranslations());
    this.getAndInitTranslations();
    this.changes.next();
  }

  getAndInitTranslations() {
    this.itemsPerPageLabel = this.translocoService.translate('paginator.itemsPerPageLabel');
    this.nextPageLabel = this.translocoService.translate('paginator.nextPageLabel');
    this.previousPageLabel = this.translocoService.translate('paginator.previousPageLabel');
    this.firstPageLabel = this.translocoService.translate('paginator.firstPageLabel');
    this.lastPageLabel = this.translocoService.translate('paginator.lastPageLabel');
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    length = Math.max(length, 0);

    if (length === 0 || pageSize === 0) {
      return `0 из ${length}`;
    }

    if (pageSize === length) {
      return `${pageSize} из ${length}`;
    }

    const startIndex = page * pageSize;
    const endIndex = startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} из ${length}`;
  }
}
