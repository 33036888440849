/**
 * Enum кодов для кастомизации функционала и интерфейса проекта. См класс @Customization
 */
export enum ErrorCodesEnum {
  FLASHCALL_LOCK_BY_ALL = 'FLASHCALL_LOCK_BY_ALL',
  FLASHCALL_LOCK_BY_IP = 'FLASHCALL_LOCK_BY_IP',
  FLASHCALL_LOCK_BY_PHONE = 'FLASHCALL_LOCK_BY_PHONE',
  FLASHCALL_EXTERNAL_BADREQUEST = 'FLASHCALL_EXTERNAL_BADREQUEST',
  CAPTCHA_NOT_VALID = 'CAPTCHA_NOT_VALID'
}
